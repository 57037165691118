@import '../theme/ColourPalette.sass'

.profileIcon
    // border-radius: 4px !important
    // background-color: $gray10 !important

.icon
    // color: $gray60
    padding-right: 10px

.user
    text-align: left

.userName
    text-transform: capitalize
    color: $ebony

.userId
    color: $lightGrey

.logoutButton
    color: $lightGrey

.linkStyle
    font-size: 14px
    font-weight: 400
    font-family: 'Outfit'
    text-decoration: none
    color: $lightGrey
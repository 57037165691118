@import '../theme/ColourPalette.sass'

@mixin common($padding, $justifyContent)
    padding: $padding
    display: flex
    justify-content: $justifyContent    

.performanceHeader
    @include common(6px 64px 20px 64px, $justifyContent: flex-end)
    max-height: 10vh
    
.performanceContainer
    background: $secondaryBase

.segments
    @include common(0px 64px 12px 64px, $justifyContent: space-between)

.detailsPageHeader
    @include common(12px 0px 14px 0px, $justifyContent: space-between)

.carrierIDStyle
    background-color: $primaryBase
    border-radius: 4px
    display: flex
    height: fit-content

.carrierIDText
    color: $gray60
    margin: 9px 3px 9px 9px!important

.carrierIDTextValue
    color: $ebony
    margin: 9px 9px 9px 3px!important

.backButton
    text-align: left

.globalFilter
    display: flex
    justify-content: flex-end

    
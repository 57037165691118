@import '../theme/ColourPalette.sass'

.loadIdStyle
    display: flex
    justify-content: space-between

.textStyle
    padding-right: 8

.cellStyle
    text-align: center
    // padding-left: 25%
    // text-align: left

.laneTextCellStyle
    text-align: left

.laneNumberCellStyle
    text-align: right

.complianceGood
    text-align: left
    font-weight: 600 !important
    color: $gray60

.complianceBad
    text-align: left
    font-weight: 600 !important
    color: $peachPink
@import '../theme/ColourPalette.sass'

.toolbar
    padding-left: 0px !important

.appTitle
    color: $indigo
    margin: 0px
    padding-right: 6em

.logo
    margin-right: 10px
    display: inline-block
    height: 56px
    width: 56px

#menu-appbar
    top: 40px

.appbarItems
    display: inline-flex
    justify-content: flex-end
    flex-grow: 1

.tab
    color: $ebony
    
// .tabLinks
//     &.Mui-selected 
//         padding: 20px 30px

.progress
    &.MuiLinearProgress-root
        height: 2px
        // background-color: $gray10
        &.MuiLinearProgress-bar1
            background-color: $lightGrey
        &.MuiLinearProgress-bar2
            background-color: $lightGrey

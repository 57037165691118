@import '../../theme/ColourPalette.sass'

@mixin rootChip($bgColor, $textColor, $deleteIconColor, $mr, $mb)
    &.MuiButtonBase-root
        &.MuiChip-root
            border-radius: 8px
            background-color: $bgColor
            color: $textColor
            margin-right: $mr
            margin-bottom: $mb
            &.MuiChip-deleteIcon
                color: $deleteIconColor

.primary
    @include rootChip($primaryBase, $spaceGrey, $primaryBase, 8px, 8px)

.chipLabel
    display: flex

.chipValueLabel
    color: $black
    
    
    
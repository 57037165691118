@import '../../theme/ColourPalette.sass'

.toggleButton
    display: flex
    flex-direction: row
    align-items: center
    padding: 8px 18px
    gap: 8px
    height: 36px
    border-radius: 6px
    align-self: stretch
    text-transform: capitalize !important

.centerTitle
    font-size: 16px
    color: $gray60

.valueText
    color: $gray60
    
.pieTitle
    font-size: 18px
    margin-top: 0px
    color: $gray60

.customslider
    .MuiSlider-rail
        background-color: $grey85 !important

.tooltip
    z-index: 1000
    opacity: 1
    color: $gray60 !important
    background: $primaryBase !important
    border-radius: 4px
    filter: drop-shadow(0px 0px 5px $lightGrey)

.pointer
    cursor: pointer
    padding: 8

.strokegreen
    stroke: $lightgreen
    stroke-width: 3

.mb1
    width: 100%
    margin-bottom: 8

.buttonGroup
    padding-bottom: 10%

.valueLable
    color: $primaryBase !important

.boxcontainer 
    align-content: flex-start
    display: flex
    justify-content: flex-start

.header-filter 
    align-content: center
    justify-content: center
    display: flex

.radioGroup
    padding: 0px 10px

.react-tooltip
    padding: 8px 8px

.responseBox
    display: flex
    background-color: $secondaryBase
    border-radius: 4px

.responseExpectation
    color: $spaceGrey
    &.MuiTypography-root
        margin: 6px 3px 6px 10px

.hoursText
    &.MuiTypography-root
        margin: 6px 10px 6px 3px

// .pieChartGrid
//     display: flex
//     justify-content: center

.pieChartGroup
    display: flex
    justify-content: space-between
    min-height: 20em

.note
    display: flex
    justify-content: flex-start
    margin: 30px 0px 0px 0px

.noteText
    color: $gray60

.noteTextBAC
    color: $gray60
    text-align: left

.noteValue
    color: $gray60
    margin-left: 4px !important

@import '../../theme/ColourPalette.sass'

@mixin rootButton($color, $textTransform, $bgColor, $textDecoration, $bgColorHoverFocus, $colorDisabled, $bgColorDisabled, $fontSize: 14px, $fontWeight: 400, $width: auto)
    &.MuiButton-root
        color: $color
        font-size: $fontSize
        border-radius: 4px
        font-weight: $fontWeight
        padding: 8px
        text-transform: $textTransform
        background-color: $bgColor
        text-decoration: $textDecoration
        width: $width
        &:hover, &:focus
            background-color: $bgColorHoverFocus
            text-decoration: $textDecoration
        &:disabled
            color: $colorDisabled
            background-color: $bgColorDisabled

.button
    @include rootButton($indigo, capitalize, transparent, none, transparent, $gray60, transparent)

.primary
    @include rootButton($primaryBase, capitalize, $indigo, none, $indigo, $primaryBase, $lightGrey)

.secondary
    @include rootButton($primaryBase, capitalize, $linkBlue, none, $linkBlue, $primaryBase, $lightGrey)

.tertiary
    @include rootButton($primaryBase, capitalize, $gray60, none, $gray60, $gray60, transparent)

.quaternary
    @include rootButton($linkBlue, capitalize, transparent, none, transparent, $gray60, transparent)

.quinary
    @include rootButton($darkBlue, none, transparent, underline, transparent, $gray60, transparent)

.senary
    @include rootButton($black, none, $greyishWhite, none, transparent, $gray60, transparent)

.septnary
    @include rootButton($ebony, none, $secondaryBase, none, $secondaryBase, $gray60, transparent, 16px, 600)

.octonary
    @include rootButton($gray60, capitalize, $faintGrey, none, $faintGrey, $faintGrey, transparent, 14px, 400, 100%)



@import '../theme/ColourPalette.sass'

.accordionStyles
    &.MuiAccordion-root
        width: 25em
        &.Mui-expanded
            margin: 0.5px 0px 0px 0px

.accordionStylesActive
    &.MuiAccordion-root
        width: 25em
        background-color: $secondaryBase
        &.Mui-expanded
            margin: 0.5px 0px 0px 0px

.accordionDetailsStyles
    &.MuiAccordionDetails-root
            padding: 0px 16px 16px
    
            
.filterTitle 
    color: $gray60         
   
@import '../theme/ColourPalette.sass'

.appt-container
    max-height: 30vh
    // box-shadow: 0px 0px 12px $spaceGrey
    border-radius: 6px

.headerColumn
    color: $ebony

.dataColumn
    color: $gray60
    
.cellStyle
    &.MuiTableCell-root
        border-bottom: 1px solid $grey20
        border-right: 1px solid $grey20
        padding: 8px

.baseLineApptDateTime
    background-color: $blue
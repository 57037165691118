@import '../../theme/ColourPalette.sass'

.pageContainer
    padding: 0px 64px 12px 64px
    background-color: $secondaryBase

.chartSection
    background-color: $primaryBase

.progressChartSection
    padding: 20px

.actionSection
    background-color: $secondaryBase

.customTextStyle
    font-size: 24px 
    font-weight:600

.progressContainer
    padding-top: 8%

.actionSectionPart
    padding: 1% 0%

@import ../../theme/ColourPalette.sass

@mixin toggle($bgColor)
    display: flex
    flex-direction: row
    align-items: center
    padding: 8px 18px
    gap: 8px
    height: 36px
    border-radius: 6px
    align-self: stretch
    text-transform: capitalize !important
    &.MuiToggleButton-root
        border: none
        background-color: $bgColor
        color: $black   
        &:hover
            background-color: $bgColor
            color: $black          

.buttonGroupContainer
    text-align: left

.buttonGroupStyle
    width: 100%
    
.toggleButtonActive
    @include toggle($lightSpaceGey)
    &.MuiToggleButton-root
        &.Mui-selected
            background-color: $mediumDarkcyanBlue
            color: $primaryBase  
            border-radius: 6px !important         
            &:hover
                background-color: $mediumDarkcyanBlue
                color: $primaryBase

.toggleButtonInactive
    @include toggle($lightSpaceGey)

.toggleButtonActivePage
    @include toggle($primaryBase)
    &.MuiToggleButton-root
        &.Mui-selected
            background-color: $purple
            color: $primaryBase  
            border-radius: 6px !important         
            &:hover
                background-color: $purple
                color: $primaryBase

.toggleButtonInactivePage
    @include toggle($primaryBase)

    
    
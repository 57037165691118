@import '../../theme/ColourPalette.sass'

.autocompletebutton
    &.MuiButton-root
        border: none !important
        color: $gray60 !important
        background-color: $primaryBase !important
        border-radius: 4px
        padding: 8px
        text-transform: capitalize
        display: flex
        justify-content: space-between

.clearall 
    text-decoration: underline
    cursor: pointer
    padding-top: 8px
    color: $linkBlue

.dt-container
    background-color: $primaryBase
    border-radius: 8px
    padding-top: 8px !important
    border: 1px solid $gray10
    box-shadow: 0px 0px 6px $spaceGrey
    .DateRangePicker__CalendarSelection
        background-color: $calBlue !important
        border: none
    .DateRangePicker__CalendarHighlight
        background-color: $calBlue !important
        opacity: .15
        border: none


.ac-container
    background-color: $primaryBase
    padding: 10px
    border-radius: 8px
    border: 1px solid $gray10
    box-shadow: 0px 0px 6px $spaceGrey

.dt-popper
    padding-top: 8px
    
.ac-popper
    padding-top: 8px
    max-width: 300px
    min-width: 300px
    z-index: 999

.ac-innerbox
    max-height: 200px
    // min-height: 200px
    overflow: auto

.ac-innerbox-selected
    // max-height: 200px
    // min-height: 200px
    overflow: auto

.ac-form
    display: block !important

.dateRangeSection
    display: flex
    align-items: center

.dateTitle
    color: $gray60
    margin-right: 8px !important

.dateValue
    color: $ebony
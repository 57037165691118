@import '../theme/ColourPalette.sass'

.eventsContainer
    // padding-left: 20px
    display: flex

.event
    display: flex

.dataBox
    display: flex
    align-items: left
    padding-bottom: 16px
    padding-left: 2px
    padding-right: 24px

.pickUpDateTime
    display: flex

.historyButton
    &.MuiButtonBase-root
        &.MuiButton-root
            min-width: 10px
            padding: 0px 5px

.stepComplete
    border-top: 1px solid $indigo
    &::before 
        content: url('../assets/indigoDotIcon.svg')
        right: 4px
        font-size: 6px
        position: relative
        top: -9px
        color: $indigo
       

.stepHighAlert
    border-top: 1px dashed $tomato
    &::before 
        content: url('../assets/tomatoDotIcon.svg')
        right: 4px
        font-size: 6px
        position: relative
        top: -9px 
        color: $tomato

.stepCritical
    border-top: 1px dashed $marigold
    &::before 
        content: url('../assets/marigoldDotIcon.svg')
        right: 4px
        font-size: 6px
        position: relative
        top: -9px  

.notThereYet
    border-top: 1px dashed $grey20
    &::before 
        // content: "⚫"
        content: url('../assets/greyDotIcon.svg')
        right: 4px
        // font-size: 5px
        font-size: 6px
        position: relative
        // top: -12px  
        top: -9px  

.textColor
    color: $gray60
    white-space: nowrap

.textColorRed
    color: $tomato
    border-bottom: 2px dotted $tomato
    white-space: nowrap

.textColorYellow
    color: $marigold
    border-bottom: 2px dotted $marigold
    white-space: nowrap

.boxStyle
    padding: 0px
    border-radius: 4px
    background-color: $primaryBase
    box-shadow: 0px 0px 12px $spaceGrey

.stopTitle
    color: $gray60
    display: flex
    flex-direction: row
    svg 
        margin: 3px

.appt-popper
    z-index: 999

@mixin eventStopBox()
    background-color: $primaryBase
    padding: 22px 18px 18px
    border-radius: 8px

.arriveBox
    @include eventStopBox()
    margin-right: 14px

.departBox
    @include eventStopBox()
    margin-right: 14px

.lockBox
    margin-left: 5px
    background-color: $grey85
    border-radius: 4px
    display: flex
    justify-content: center

.noteApptHistory
    border-top: 1px solid $grey20
    padding: 3px 0px 3px 10px
    color: $gray60
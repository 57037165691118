@import '../../theme/ColourPalette.sass'

@mixin card()
    // height: auto
    background-color: $primaryBase
    border-radius: 8px
    padding: 20px

@mixin title($padding)
    display: flex
    justify-content: space-between
    padding: $padding
    

.card1
    @include card()
    margin-bottom: 5px
    height: 100%
    // min-height: 250px

.card2
    @include card()
    margin-bottom: 12px
    height: 40%

.card3
    @include card()
    margin-bottom: 9px
    height: 57.5%

.card4
    @include card()
    margin-bottom: 12px
    height: 40%

.card5
    @include card()
    margin-bottom: 12px

.titleBox
    @include title($padding: 2px 2px 18px 2px)

.lacSubtitle
    color: $gray60

.titleStyle
    color: $ebony

.subTitle
    @include title($padding: 2px 2px 18px 2px)

.subSubTitle
    @include title($padding: 2px)

.ratio
    display: flex

.ratioLAC
    display: flex
    padding-top: 4%

.moreButton
    &.MuiButton-root
        min-width: 4px
    
.good 
    color: $skyBlue

.bad
    color: $peachPink

.csvButtonCP
    &.MuiButtonBase-root
        &.MuiButton-root
            min-width: 30px
            max-height: 20px
            // margin-top: 7px
            margin-left: 6px 

.gridContainer
    padding-top: 4%

.grid1
    text-align: left

.grid2
    display: flex
    justify-content: center

.subGrid
    margin-top: 10px

.loadAccepted
    color: $ebony

.barContainer
    margin-top: 10%
    min-height: 10em
    margin-bottom: 6%

.checkboxFilterLAC
    padding-top: 4%
    display: flex
    justify-content: flex-end

.buffer
    padding-top: 4%

.totalComplianceTitle
    color: $gray60

.target
    color: $gray60

.dividerStyle
    margin-top: 4% !important
    margin-bottom: 4% !important
    color: $whiteGrey

.subTitleStyle
    color: $gray60

.styleCRECBars
    min-height: 20em

.subtitleCER
    text-align: left

.subTitleStyleCER
    font-weight: 600 !important
    
.subtitleContsiner
    margin-bottom: 2em

.styleFKYCBar
    min-height: 5em

.styleBABar
    min-height: 4em
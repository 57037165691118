@import '../theme/ColourPalette.sass'

.table
  border-collapse: collapse
  background-color: $primaryBase
  border-radius: 8px
  box-sizing: border-box
  width: auto

.head
  padding-top: 12px
  padding-bottom: 12px
  text-align: center
  color: $gray60
  border-bottom: 0.5px solid $gray10

.row
  border-bottom: 0.5px solid $gray10
  white-space: nowrap

.bodyCell
  &.MuiTableCell-root
    color: $lightGrey
    // text-align: center

.noRecodsRow
  height: 68vh
  &.MuiTableCell-root
    text-align: center

.sub-row
  background-color: $secondaryBase

.sub-row-component
  padding: 0px 10px

.button-group
  padding-bottom: 12px

.pagination
  padding-top: 1em
  &	.MuiPaginationItem-root
    &.MuiPaginationItem-text
      color: $indigo
    &.Mui-selected
      background-color: $primaryBase

.tableContainer
  max-height: 74vh 
  border-radius: 12px
  height: 72vh
  background-color: $primaryBase !important

.tableHeader
  color: $darkGrey80
  // white-space: nowrap

.noData
  color: $gray60

.paginationDiv
  display: flex
  justify-content: space-between

.rowsPerPage
  padding: 1em

.recordTextStyle
  color: $indigo

.subrowComponentActionButtons
  max-width: 90vw
@import '../theme/ColourPalette.sass'

.login
    background: url("../assets/carrierPortalLanding.jpg") no-repeat
    background-size: cover
    height: 100%

.loginBox
    display: inline-flex
    justify-content: center
    align-items: baseline
    padding-top: 10%
    width: 100%

.title
    font-size: 64px
    font-weight: 700
    padding-left: 24px
    margin-bottom: 4px
    color: $primaryBase
    letter-spacing: 2px

.welcomeText
    font-size: 64px
    font-weight: 700
    color: $gray10

.loginDiv
    background: $gray10
    padding: 12px 24px
    margin-top: 5%
    width: 10%
    border-radius: 8px
    display: inline-flex
    align-items: center
    justify-content: center
    color: $indigo
    font-size: 16px
    font-weight: 600
    text-decoration: none

.loginDiv:hover
    box-shadow: 0px 0px 6px 4px $lightSteelBlue
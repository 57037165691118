@import '../theme/ColourPalette.sass'

@mixin filter-button($color)
    &.MuiButtonBase-root
        &.MuiButton-root
            background-color: $color
            min-width: 30px
            max-height: 32px
            margin-top: 8px
            margin-left: 8px
            margin-right: 6px 
                
.containerBoxTNTHeader
    display: flex
    justify-content: space-between
    padding: 10px 6px 0px 0px

.filterChips
    text-align: start
    display: flex
    flex-wrap: wrap

.operationsSection
    display: flex
    justify-content: flex-end

.popperStyles
    z-index: 3
    background-color: $primaryBase
    border-radius: 4px
    border-bottom: 0.5px solid $gray10
    max-height: 80vh
    overflow-y: auto

.csvButtonStyles
    &.MuiButtonBase-root
        &.MuiButton-root
            min-width: 30px
            max-height: 32px
            margin-top: 7px
            margin-left: 6px 
.exportText
    margin-top: 12px
    
.activeFilterButton
    @include filter-button($mediumDarkcyanBlue)

.filterButtonDefault
    @include filter-button($secondaryBase)

.searchField
    margin-right: 10px

.refreshButtonStyles
    &.MuiButtonBase-root
        &.MuiButton-root
            min-width: 30px
            max-height: 32px
            margin-top: 7px
            margin-left: 1px 
            color: $indigo
     
.lastUpdate
    color: $gray60
    padding-bottom: 16px
    padding-left: 4px
    text-align: left
    
.delete-button
    display: flex
    justify-content: center 
    
.calender-styles
    padding: 2px 8px 2px 8px
    display: flex
    align-items: center

.calendar-input
    margin-right: 5px
    width: 100%

.textfield-style
    width: 100%
                                            
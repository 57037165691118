@import '../theme/ColourPalette.sass'

.commentTitle
    &.MuiTypography-root
        &.MuiDialogTitle-root
            display: flex
            justify-content: space-between

.commentButton
    &.MuiButton-root
        &.MuiButtonBase-root
            width: 100%

.noComment
    display: flex
    justify-content: center
    margin: 8%

.textArea
    width: 100%
    padding: 10px
    border-radius: 6px
    border-color: $faintGrey
    margin-top: 10px

.commentDateTime
    text-align: right

.alert
    color: $tomato

.comment
    color: $gray60
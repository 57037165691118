@import ../../theme/ColourPalette.sass

.textfield-styles
    &.MuiFormControl-root 
        &.MuiTextField-root 
            background-color: $primaryBase
    // &.MuiOutlinedInput-notchedOutline
    //     border-radius: 4px
    //     border-color: $primaryBase 
.info-text
    text-align: left
    color: $lightGrey
@import '../../theme/ColourPalette.sass'

.ellipsis
    white-space: nowrap
    width: 50px
    overflow: hidden
    text-overflow: ellipsis

@mixin popper-button()
    color: $ebony !important
    background-color: $grey !important
    border-radius: 4px
    // border: none !important
    font-size: 12px
    font-weight: 400
    font-family: 'Outfit'
    padding: 8px
    text-transform: capitalize
    display: flex
    justify-content: space-between
    width: 10vw
    max-width: 10vw

.popper-open-button-white
    &.MuiListItemButton-root
        border: none !important
        @include popper-button()

.popper-open-button-indigo
    &.MuiListItemButton-root
        @include popper-button()
        border: 1px solid $indigo

.popper-open-button-red
    &.MuiListItemButton-root
        @include popper-button()
        border: 1px solid $tomato

.popper-open-button-white-cal
    &.MuiButton-root
        border: none !important
        @include popper-button()

.popper-open-button-red-cal
    &.MuiButton-root
        border: 1px solid $tomato !important
        @include popper-button()

.popper-open-button-indigo-cal
    &.MuiButton-root
        border: 1px solid $indigo !important
        @include popper-button()

.popper-container
    border: none
    margin-left: 8px
    min-width: 200px

.popper-body
    &.MuiList-root
        border: none
        border-radius: 8px

.nested-list-button
    height: 24px

.list-item-button
    padding-left: 4px
    background: $gray10

.container-dropdown
    background: $secondaryBase
    padding: 0px 64px 12px 64px
    overflow: auto
    max-height: 150px
    z-index: 1000
        
    ::-webkit-scrollbar 
        width: 6px
        height: 6px

    /* Track */
    ::-webkit-scrollbar-track 
        background: $gray10 
        border-radius: 100px

    /* Handle */
    ::-webkit-scrollbar-thumb 
        background: $lightGrey
        border-radius: 100px
@import '../theme/ColourPalette.sass'

.topScrollPaper 
    align-items: flex-start !important

.topPaperScrollBody
    vertical-align: top

.dialogTitle
    display: flex

.failedTitle
    color: $darkGrey80
    padding-top: 6px

.failedList
    color: $gray60